<template>
    <div>
        <div>
            <school-banner :backgroundImg="proxyBannerImg" :schoolImg="schoolImg" :contactImg="proxyContactImg" :name="universityName" :province="universityProvince" :characteristic="universityCharacteristic" :website="universityWebsite" :phone="universityPhone" :email="universityEmail"></school-banner>
        </div>
        <div class="content-box">
            <div class="content-left">
                <div class="content-title">[{{ majorCode }}]{{ majorName }}</div>
                <header-entry>专业信息</header-entry>
                <div class="content-border content-major">
                    <div class="content-major-item">
                        <span class="content-major-item-title">所属学校：</span>
                        <span class="content-major-item-text">{{ universityName }}</span>
                    </div>
                    <div class="content-major-item">
                        <span class="content-major-item-title">所属学院：</span>
                        <span class="content-major-item-text">{{ collegeName }}</span>
                    </div>
                    <div class="content-major-item">
                        <span class="content-major-item-title">招生年份：</span>
                        <span class="content-major-item-text">{{ majorEnrollmentYear }}</span>
                    </div>
                    <div class="content-major-item">
                        <span class="content-major-item-title">学习方式：</span>
                        <span class="content-major-item-text">{{ majorLearnway }}</span>
                    </div>
                    <div class="content-major-item">
                        <span class="content-major-item-title">所属门类：</span>
                        <span class="content-major-item-text">{{ majorBelongcategory }}</span>
                    </div>
                    <div class="content-major-item">
                        <span class="content-major-item-title">所属一级学科：</span>
                        <span class="content-major-item-text">{{ majorBelongFirstDiscipline }}</span>
                    </div>
                    <div class="content-major-item">
                        <span class="content-major-item-title">统考计划招生人数：</span>
                        <span class="content-major-item-text">{{ majorPlannedEnrollment }}（不含推免）</span>
                    </div>
                </div>
                <header-entry>考试科目</header-entry>
                <div class="content-border content-subject">
                    <div class="content-subject-item">
                        <span class="content-subject-item-title">研究方向</span>
                        <span class="content-subject-item-text">{{ majorResearchDirection }}</span>
                    </div>
                    <div class="content-subject-item">
                        <span class="content-subject-item-title">初试科目</span>
                        <span class="content-subject-item-text">{{ majorFirstSubject }}</span>
                    </div>
                    <div class="content-subject-item">
                        <span class="content-subject-item-title">复试科目</span>
                        <span class="content-subject-item-text">{{ majorSecondSubject }}</span>
                    </div>
                </div>
                <template v-if="firstDocumentList.length > 0">
                    <header-entry>初试资料（点击可预览）</header-entry>
                    <div class="content-border content-document">
                        <template v-for="(val, key) in firstDocumentGroupList">
                            <template v-if="val.length > 0">
                                <div class="content-document-title">{{ key }}</div>
                                <div v-for="docuemntInfo in val" class="content-document-item">
                                    <span class="content-document-item-name" @click="onDocumentClick(docuemntInfo)">{{ docuemntInfo.DocumentName }}</span>
                                    <span class="content-document-item-price">￥{{ docuemntInfo.DocumentPrice }}</span>
                                </div>
                            </template>
                        </template>
                    </div>
                </template>
                <template v-if="secondDocumentList.length > 0">
                    <header-entry>复试资料（点击可预览）</header-entry>
                    <div class="content-border content-document">
                        <template v-for="docuemntInfo in secondDocumentList">
                            <div class="content-document-item">
                                <span class="content-document-item-name" @click="onDocumentClick(docuemntInfo)">{{ docuemntInfo.DocumentName }}</span>
                                <span class="content-document-item-price">￥{{ docuemntInfo.DocumentPrice }}</span>
                            </div>
                        </template>
                    </div>
                </template>
                <template v-if="courseInfos.length > 0">
                    <header-entry>视频网课（点击可试看）</header-entry>
                    <div class="content-border content-document">
                        <template v-for="(val, key) in courseGroupList">
                            <template v-if="val.length > 0">
                                <div class="content-document-title">{{ key }}</div>
                                <div v-for="courseInfo in val" class="content-document-item">
                                    <span class="content-document-item-name" @click="onCourseClick(courseInfo)">{{ courseInfo.CourseName }}</span>
                                    <span class="content-document-item-price">￥{{ courseInfo.CourseSellingPrice }}</span>
                                </div>
                            </template>
                        </template>
                    </div>
                </template>
                <template v-if="courseCustoms.length > 0">
                    <header-entry>专属定制服务（点击可试看）</header-entry>
                    <div class="content-border content-document">
                        <template v-for="courseInfo in courseCustoms">
                            <div class="content-document-item">
                                <span class="content-document-item-name" @click="onCourseClick(courseInfo)">{{ courseInfo.CourseName }}</span>
                                <span class="content-document-item-price">￥{{ courseInfo.CourseSellingPrice }}</span>
                            </div>
                        </template>
                    </div>
                </template>
                <template v-if="admitInfos.length > 0">
                    <header-entry>报录数据（点击可查看）</header-entry>
                    <div class="content-border content-document">
                        <template v-for="admitInfo in admitInfos">
                            <div class="content-document-item">
                                <span class="content-document-item-name" @click="onAdmitClick(admitInfo)">{{ admitInfo.AdmitName }}</span>
                                <span class="content-document-item-price">数据会员</span>
                            </div>
                        </template>
                    </div>
                </template>
            </div>
            <div class="content-right">
                <school-hot></school-hot>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderEntry from "@/components/PC/HeaderEntry";
import SchoolBanner from "@/components/PC/SchoolBanner";
import SchoolHot from "@/components/PC/SchoolHot";
import Tools from "@/tool";
import Config from "@/api/service";
import Manager from "@/manager";
export default {
    name: "PCModule",
    components: {
        HeaderEntry,
        SchoolBanner,
        SchoolHot,
    },
    data() {
        return {
            universityCode: null,
            universityName: null,
            universityProvince: null,
            universityWebsite: null,
            universityPhone: null,
            universityEmail: null,
            universityCharacteristic: null,
            collegeCode: null,
            collegeName: null,
            majorCode: null,
            majorName: null,
            majorLearnway: null,
            majorEnrollmentYear: null,
            majorBelongcategory: null,
            majorBelongFirstDiscipline: null,
            majorResearchDirection: null,
            majorFirstSubject: null,
            majorSecondSubject: null,
            majorPlannedEnrollment: null,
            firstDocumentList: [],
            secondDocumentList: [],
            admitInfos: [],
            courseInfos: [],
            courseCustoms: [],
        };
    },
    computed: {
        schoolImg() {
            if (this.universityCode) {
                return `${Config.school.logo}?SchoolCode=${this.universityCode}`;
            }
        },
        proxyBannerImg() {
            return Config.proxy.image + `?ProxyCode=${this.$store.state.proxyCode}&ImageIdentity=6`;
        },
        proxyContactImg() {
            return Config.proxy.image + `?ProxyCode=${this.$store.state.proxyCode}&ImageIdentity=1`;
        },
        courseGroupList() {
            if (this.courseInfos) {
                var group = {
                    全程班: [],
                    导学课: [],
                    基础班: [],
                    强化班: [],
                    冲刺班: [],
                    其他: [],
                };
                for (var i = 0; i < this.courseInfos.length; i++) {
                    if (this.courseInfos[i].CourseTag == "全程班") {
                        group.全程班.push(this.courseInfos[i]);
                    } else if (this.courseInfos[i].CourseTag == "导学课") {
                        group.导学课.push(this.courseInfos[i]);
                    } else if (this.courseInfos[i].CourseTag == "基础班") {
                        group.基础班.push(this.courseInfos[i]);
                    } else if (this.courseInfos[i].CourseTag == "强化班") {
                        group.强化班.push(this.courseInfos[i]);
                    } else if (this.courseInfos[i].CourseTag == "冲刺班") {
                        group.冲刺班.push(this.courseInfos[i]);
                    } else {
                        group.其他.push(this.courseInfos[i]);
                    }
                }
                return group;
            }
        },
        firstDocumentGroupList() {
            if (this.firstDocumentList) {
                var group = {
                    全套精品: [],
                    基础夯实: [],
                    强化巩固: [],
                    冲刺模拟: [],
                    其他: [],
                };
                var items = this.firstDocumentList.filter(p => p.DocumentType == 0);
                for (var i = 0; i < items.length; i++) {
                    if (items[i].DocumentName.indexOf('【电子书】') >= 0 || items[i].DocumentName.indexOf('【初试】') >= 0) {
                        group.全套精品.push(items[i]);
                    } else if (items[i].DocumentName.indexOf('【基础】') >= 0) {
                        group.基础夯实.push(items[i]);
                    } else if (items[i].DocumentName.indexOf('【强化】') >= 0) {
                        group.强化巩固.push(items[i]);
                    } else if (items[i].DocumentName.indexOf('【冲刺】') >= 0) {
                        group.冲刺模拟.push(items[i]);
                    } else {
                        group.其他.push(items[i]);
                    }
                }
                return group;
            }
        },
    },
    methods: {
        //获取路由参数
        getRoutParams() {
            this.universityCode = this.$route.params.universityCode;
            this.collegeCode = this.$route.params.collegeCode;
            this.majorCode = this.$route.params.majorCode;
            this.majorLearnway = this.$route.params.majorLearnway;
        },
        //获取是否显示关联课程列表
        getIsShowRelatedCourse() {
            var model = {
                ProxyCode: this.$store.state.proxyCode,
            };
            return this.$axios
                .post(Config.proxy.relatedCourseVisible, model)
                .then((res) => {
                    return res.data.Data.ShowRelatedCourse;
                })
                .catch((error) => {
                    this.$dialog({ title: "错误", message: error.message });
                });
        },
        //获取大学信息
        getUniversityInfo() {
            var model = {
                UniversityCode: this.universityCode,
            };
            this.$axios
                .post(Config.school.schoolInfo, model)
                .then((res) => {
                    this.universityName = res.data.Data.UniversityName;
                    this.universityProvince = res.data.Data.UniversityProvince;
                    this.universityWebsite = res.data.Data.UniversityWebsite;
                    this.universityPhone = res.data.Data.UniversityPhone;
                    this.universityEmail = res.data.Data.UniversityEmail;
                    this.universityCharacteristic = res.data.Data.UniversityCharacteristic;
                    this.addVisitProductRecord();
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //获取学院信息
        getCollegeInfo() {
            var model = {
                UniversityCode: this.universityCode,
                CollegeCode: this.collegeCode
            };
            this.$axios
                .post(Config.school.collegeInfo, model)
                .then((res) => {
                    this.collegeName = res.data.Data.CollegeName;
                    this.addVisitProductRecord();
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //获取专业信息
        getMajorInfo() {
            var model = {
                UniversityCode: this.universityCode,
                CollegeCode: this.collegeCode,
                MajorCode: this.majorCode,
                MajorLearningWay: this.majorLearnway,
            };
            this.$axios
                .post(Config.school.majorInfo, model)
                .then((res) => {
                    this.majorName = res.data.Data.MajorName;
                    this.majorEnrollmentYear = res.data.Data.MajorEnrollmentYear;
                    this.majorBelongcategory = res.data.Data.MajorBelongcategory;
                    this.majorBelongFirstDiscipline = res.data.Data.MajorBelongFirstDiscipline;
                    this.majorResearchDirection = res.data.Data.MajorResearchDirection;
                    this.majorFirstSubject = res.data.Data.MajorFirstSubject;
                    this.majorSecondSubject = res.data.Data.MajorSecondSubject;
                    this.majorPlannedEnrollment = res.data.Data.MajorPlannedEnrollment;
                    this.addVisitProductRecord();
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //获取关联文档列表
        getDocumentInfos() {
            //初试列表
            var model = {
                UniversityCode: this.universityCode,
                CollegeCode: this.collegeCode,
                MajorCode: this.majorCode,
            };
            this.$axios
                .post(Config.school.related.firstDocumentList, model)
                .then((res) => {
                    this.firstDocumentList = res.data.Data.List;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
            //复试列表
            var model = {
                UniversityCode: this.universityCode,
                CollegeCode: this.collegeCode,
                MajorCode: this.majorCode,
            };
            this.$axios
                .post(Config.school.related.secondDocumentList, model)
                .then((res) => {
                    this.secondDocumentList = res.data.Data.List;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //获取关联课程列表
        getCourseInfos() {
            var model = {
                UniversityCode: this.universityCode,
                CollegeCode: this.collegeCode,
                MajorCode: this.majorCode,
            };
            this.$axios
                .post(Config.school.related.courseList, model)
                .then((res) => {
                    this.courseInfos = res.data.Data.List;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //获取关联课程定制列表
        getCourseCustoms() {
            var model = {
                PageIndex: 1,
                PageSize: 100,
            };
            this.$axios
                .post(Config.school.related.customList, model)
                .then((res) => {
                    this.courseCustoms = res.data.Data.List;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //获取关联报录列表
        getAdmitInfos() {
            var model = {
                UniversityCode: this.universityCode,
                CollegeCode: this.collegeCode,
                MajorCode: this.majorCode,
            };
            return this.$axios
                .post(Config.school.related.admitList, model)
                .then((res) => {
                    this.admitInfos = res.data.Data.List;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //添加访问商品记录
        addVisitProductRecord() {
            if (this.universityName && this.collegeName && this.majorName) {
                Manager.addVisitProductRecord(`查院校 ${this.universityName}-${this.collegeName}-${this.majorName}`);
            }
        },
        //添加访问学校记录
        addVisitSchoolRecord() {
            if (this.universityCode && this.collegeCode && this.majorCode) {
                Manager.addVisitSchoolRecord({
                    UniversityCode: this.universityCode,
                    CollegeCode: this.collegeCode,
                    MajorCode: this.majorCode,
                    RecordModule: 6,
                });
            }
        },
        //点击资料
        onDocumentClick(documentInfo) {
            this.$router.push({
                name: 'Document1',
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    documentGuid: documentInfo.DocumentGuid
                }
            });
        },
        //点击课程
        onCourseClick(courseInfo) {
            this.$router.push({
                name: 'Course1',
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    courseGuid: courseInfo.CourseGuid
                }
            });
        },
        //点击报录数据
        onAdmitClick(admitInfo) {
            this.$router.push({
                name: "Admit1",
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    universityCode: admitInfo.AdmitUniversityCode,
                    collegeCode: admitInfo.AdmitCollegeCode,
                    majorCode: admitInfo.AdmitMajorCode,
                    majorLearnway: '全日制'
                }
            });
        },
    },
    async mounted() {
        this.getRoutParams();
        this.getUniversityInfo();
        this.getCollegeInfo();
        this.getMajorInfo();
        this.getDocumentInfos();
        this.getAdmitInfos();
        if (await this.getIsShowRelatedCourse()) {
            this.getCourseInfos();
            this.getCourseCustoms();
        }
        this.addVisitSchoolRecord();
    }
};
</script>
<style scoped>
.content-box {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.content-left {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.content-right {
    width: 300px;
    margin: 0 0 0 50px;
}

.content-title {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    margin: 15px 0;
}

.content-border {
    border: 1px solid #e6e6e6;
    padding: 10px 30px;
}

.content-major {
    margin: 10px 0;
}

.content-subject {
    margin: 10px 0;
}

.content-document {
    margin: 10px 0;
}

.content-major-item {
    display: inline-block;
    width: 50%;
    margin: 10px 0;
}

.content-major-item-title {
    color: #555;
    font-size: 14px;
    font-weight: 700;
}

.content-major-item-text {
    color: #555;
    font-size: 14px;
}

.content-subject-item {
    display: flex;
    margin: 20px 0;
}

.content-subject-item-title {
    color: #555;
    font-size: 14px;
    font-weight: 700;
}

.content-subject-item-text {
    color: #555;
    font-size: 14px;
    margin-left: 20px;
    white-space: pre-wrap;
}

.content-document-title {
    font-size: 14px;
    font-weight: 600;
    margin: 20px 0px 10px 0;
}

.content-document-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: #555;
    font-size: 14px;
    margin: 10px 0;
}

.content-document-item-name {
    flex: 1;
    margin-right: 20px;
    cursor: pointer;
    color: #555;
    font-size: 14px;
}

.content-document-item-name:hover {
    color: #FE5E03;
    text-decoration: underline;
    text-underline-offset: 0.2em;
}

.content-document-item-price {
    min-width: 40px;
    color: #555;
    font-size: 14px;
}
</style>