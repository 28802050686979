<template>
  <div class="box" :style="`background-image:url(${backgroundImg})`">
    <div class="box1">
      <el-image :lazy="true" :src="schoolImg" class="school-img"></el-image>
    </div>
    <div class="box2">
      <div class="line-text">
        <span class="school-title">{{ name }}</span>
      </div>
      <div class="line-text">
        <img src="@/assets/images/pc/banner-position.png" class="school-icon" />
        <span class="school-province">{{ province }}</span>
      </div>
      <div class="line-text">
        <span v-for="item in characteristics" class="school-characteristic">{{ item }}</span>
      </div>
      <div class="line-text">
        <img src="@/assets/images/pc/banner-website.png" class="school-icon" />
        <span class="school-website">官方网址：{{ website }}</span>
      </div>
      <div class="line-text">
        <img src="@/assets/images/pc/banner-phone.png" class="school-icon" />
        <span class="school-phone">官方电话：{{ phone }}</span>
      </div>
      <div class="line-text">
        <img src="@/assets/images/pc/banner-email.png" class="school-icon" />
        <span class="school-email">电子邮箱：{{ email }}</span>
      </div>
    </div>
    <div class="box3">
      <el-image :src="contactImg" class="contact-img"></el-image>
      <span class="contact-text">微信客服，扫码咨询</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SchoolBanner",
  components: {

  },
  props: {
    backgroundImg: {
      type: String,
      default: '@/assets/images/pc/bg.png',
    },
    schoolImg: {
      type: String,
      default: '@/assets/images/pc/school.png',
    },
    contactImg: {
      type: String,
      default: '@/assets/images/pc/contact.png',
    },
    name: {
      type: String,
      default: '北京大学',
    },
    province: {
      type: String,
      default: '北京',
    },
    characteristic: {
      type: String,
      default: '高等院校 综合类 A区',
    },
    website: {
      type: String,
      default: 'https://www.sjtu.edu.cn/',
    },
    phone: {
      type: String,
      default: '021-54740000 021-62821069',
    },
    email: {
      type: String,
      default: 'yzb-ss@sjtu.edu.cn',
    },
  },
  data() {
    return {};
  },
  computed: {
    characteristics() {
      if (this.characteristic) {
        return this.characteristic.split(' ');
      }
    }
  },
  methods: {

  },
  mounted() {

  }
};
</script>

<style scoped>
.box {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.box1 {
  width: 220px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.box3 {
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.line-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0px;
}

.school-img {
  width: 120px;
  height: 120px;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
}

.school-icon {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.school-title {
  color: white;
  font-size: 28px;
}

.school-province {
  color: white;
  font-size: 15px;
}

.school-characteristic {
  width: 80px;
  text-align: center;
  color: white;
  font-size: 15px;
  border: 1px solid white;
  border-radius: 20px;
  padding: 5px;
  margin-right: 10px;
}

.school-website {
  color: white;
  font-size: 15px;
}

.school-phone {
  color: white;
  font-size: 15px;
}

.school-email {
  color: white;
  font-size: 15px;
}

.contact-img {
  width: 100px;
  height: 100px;
}

.contact-text {
  color: white;
  font-size: 14px;
  margin-top: 10px;
}
</style>
